import { ICompanyConfig } from 'interfaces/company.interface'
import { create } from 'zustand'

export interface InspectionConfigurationStore {
  configuration?: {
    company: Partial<ICompanyConfig>
    locale: string
    inspectionId: string
  }
  setConfiguration: (
    newState: InspectionConfigurationStore['configuration'],
  ) => void
}

export const useInspectionConfigurationStore =
  create<InspectionConfigurationStore>((set) => ({
    setConfiguration: (configuration) => {
      set((state) => ({
        configuration,
      }))
    },
  }))
