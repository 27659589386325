import { ThemeWrapper } from 'components/ThemeWrapper'
import WhatsappFloatButton from 'components/WhatsappFloatButton'

function Page404() {
  return (
    <ThemeWrapper>
      <div className='p-4 flex flex-col'>
        <div className='flex flex-col items-center justify-center py-8 relative'>
          <img
            className='w-[250px] h-[250px]'
            src='/assets/svg/robot.svg'
            alt='Error robot'
          />
        </div>
        <div className='flex flex-col items-center justify-center'>
          <p className='font-bold text-4xl'>404</p>
          <p className='font-bold text-3xl'>Página no encontrada</p>
          <p className='text-sm text-center mt-4'>
            La página que intenta visitar no existe, si necesita comunicarse con
            nosotros, utilice el botón de whatsapp.
          </p>
        </div>
        <WhatsappFloatButton className='bottom-[50px]' />
      </div>
    </ThemeWrapper>
  )
}

export default Page404
