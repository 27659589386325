import { IconBrandWhatsapp } from '@tabler/icons-react'
import { useInspectionConfigurationStore } from 'stores/inspection-configuration.store'
import { Button, cn } from 'ui'
import { localeString } from 'utils/locale-string'

export interface IWhatsappFloatButtonProps {
  className?: string
  disabledFloat?: boolean
}

function WhatsappFloatButton(props: IWhatsappFloatButtonProps) {
  const configuration = useInspectionConfigurationStore((s) => s.configuration)

  const numberVariants = configuration?.company?.wspIntegration?.links.reduce(
    (acc, curr) => {
      const { locale, number, ...rest } = curr

      return {
        ...acc,
        [locale]: number,
      }
    },
    {},
  )

  const messageVariants = configuration?.company?.wspIntegration?.links.reduce(
    (acc, curr) => {
      const { locale, message, ...rest } = curr

      return {
        ...acc,
        [locale]: message,
      }
    },
    {},
  )

  if (typeof window === 'undefined') return null

  if (props.disabledFloat)
    return (
      <a
        href={`https://wa.me/${localeString(
          numberVariants,
          '5491171986455',
          configuration?.locale,
        )}?text=${encodeURIComponent(
          localeString(
            messageVariants,
            `Hola Autoinspector, necesito realizar una consulta sobre la inspección #${
              configuration?.inspectionId || ''
            } de ${
              configuration?.company.fantasyName ||
              configuration?.company.societyReason ||
              ''
            }. Me encuentro en el siguiente enlace: ${window.location.href}`,
            configuration?.locale,
          ),
        )}`}
        rel='noopener noreferrer'
        className='w-full'
      >
        <Button className='w-full text-foreground' variant='link'>
          Necesito ayuda
        </Button>
      </a>
    )

  return (
    <div
      className={cn(
        'fixed bottom-[7.5rem] right-[20px] z-[5]',
        props.className,
      )}
    >
      <a
        href={`https://wa.me/${localeString(
          numberVariants,
          '5491171986455',
          configuration?.locale,
        )}?text=${encodeURIComponent(
          localeString(
            messageVariants,
            `Hola Autoinspector, necesito realizar una consulta sobre la inspección #${
              configuration?.inspectionId || ''
            } de ${
              configuration?.company.fantasyName ||
              configuration?.company.societyReason ||
              ''
            }. Me encuentro en el siguiente enlace: ${window.location.href}`,
            configuration?.locale,
          ),
        )}`}
        rel='noopener noreferrer'
      >
        <Button
          variant='ghost'
          className='w-[56px] h-[56px] rounded-full shadow-xl bg-gray-200 text-foreground'
        >
          <IconBrandWhatsapp className='h-6 w-6' />
        </Button>
      </a>
    </div>
  )
}

export default WhatsappFloatButton
