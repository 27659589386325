import { ILocale } from 'interfaces/inspection.interface'

export const localeString = (
  variants: ILocale = {},
  defaultString: string,
  locale?: string
): string => {
  if (!locale) {
    return defaultString
  }

  return variants[locale] || defaultString
}
